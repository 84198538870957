import Vue from 'vue'

// 详细地址转换成GPS
export const getPointByAddress = (address) => {
  // 创建地理编码实例
  const myGeo = new BMapGL.Geocoder();
  return new Promise((resolve,reject) => { // 通过Promise将回调函数从异步改为同步
    myGeo.getPoint(address, (point) => {
      if (point) {
        // 地理编码成功，返回经纬度坐标对象
        resolve(point);
      } else {
        // 地理编码失败
        reject('地理编码失败');
      }
    }, '全国');
  })
}

// 递归循环，去除childrenList为空时的该属性
export function removeChildren(tree){
  if(tree.childrenList && tree.childrenList.length == 0){
    Vue.delete(tree,'childrenList'); // 去除某个属性
    return tree;
  }else{
    tree.childrenList.forEach(element=>{
      removeChildren(element);
    })
  }
}

// 获取联动选择器回显文字
export function getCascaderLabel(id,arr,text){
  if(id.length == 0){
    return text;
  }else{
    for(let i=0;i<arr.length;i++){
      if(arr[i].id == id[0]){
        text = text + arr[i].name;
        id.splice(0,1);
        return getCascaderLabel(id,arr[i].childrenList,text);
      }
    }
  }
}

// 获取省市区多选联动框回显文字
export function getPCA(pcaOptions,choosedItem){ // (省市区选项，选中的id)
  let pca = {
    city:'',
    area:'',
    street:'',
  }
  for(let i=0;i<pcaOptions.length;i++){
    if(pcaOptions[i].value == choosedItem[0]){
      pca.city = pcaOptions[i].label;
      let children = pcaOptions[i].children;
      for(let k=0;k<children.length;k++){
        if(children[k].value == choosedItem[1]){
          pca.area=children[k].label;
          let child = children[k].children;
          for(let j=0;j<child.length;j++){
            if(child[j].value == choosedItem[2]){
              pca.street = child[j].label;
              return pca;
            }
          }
          break;
        }
      }
      break;
    }
  }
}

// 时间戳转时间
export function timeToTime (time,type) {
    const date = new Date(Number(time))
    const Y = date.getFullYear() // 年
    const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
    const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
    const H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 时
    const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 分
    const S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 秒 
    let Time;
    if(type == 'day'){
      Time = Y + '-' + M + '-' + D;
    }else if(type == 'month'){
      Time = Y + '-' + M;
    }else if(type == 'minutes'){
      Time = `${Y}-${M}-${D} ${H}:${m}`;
    }else if(type == 'hour'){
      Time = `${Y}-${M}-${D} ${H}:00:00`;
    }else if(type == 'seconds'){
      Time = `${Y}-${M}-${D} ${H}:${m}:${S}`;
    }else{
      Time = {
        year:Y,
        month:M,
        day:D,
        hour:H,
        minutes:m,
        seconds:S,
      }
    }
    return Time;
}

// 中国标准时间转年月日
export function timeChange (time,type) {
  var chinaStandard=time;
    var date = new Date(chinaStandard);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    var minute = date.getMinutes();
    minute = minute < 10 ? ('0' + minute) : minute;
    var second = date.getSeconds();
    second = second < 10 ? ('0' + second) : second;
    let Time;
    if(type == 'day'){
      Time = y + '-' + m + '-' + d;
    }else if(type == 'month'){
      Time = y + '-' + m;
    }else if(type == 'minutes'){
      Time = `${y}-${m}-${d} ${h}:${minute}`;
    }else if(type == 'hour'){
      Time = `${y}-${m}-${d} ${h}:00:00`;
    }else if(type == 'seconds'){
      Time = `${y}-${m}-${d} ${h}:${minute}:${second}`;
    }else{
      Time = {
        year:y,
        month:m,
        day:d,
        hour:h,
        minutes:minute,
        seconds:second,
      }
    }
    return Time;
}

// 根据总秒数转换成 --时--分--秒
export function secondToMinite(mins){
  let hour = 0,min = 0;
  hour = parseInt(mins / 60);
  min = mins % 60;
  return {
    hour,
    min,
  }
}

// 表单验证
// 数值必须大于零
export function MakeMaxNumRule (rule, value, callback) {
  if (value <= 0) {
    callback(new Error('该数字须大于零'));
  } else {
    callback();
  }
};

// 手机号码验证
export function phoneRight (rule, value, callback) {
  let phoneRule = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  let telephoneRegex = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
  if (phoneRule.test(value) || telephoneRegex.test(value)) {
    callback();
  } else {
    callback(new Error('请输入正确的联系方式'));
    
  }
}

// 校验版本号
export const validateVersion = (rule, value, callback) => {
  let reg = /^(V)([1-9]\d|[1-9])(.([1-9]\d|\d)){2}$/;
  if (reg.test(value)) {
    callback();
  } else {
    callback(new Error('请填写以V开头形式正确的版本号，例：V1.1.2'));
  }
}