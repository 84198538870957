<!-- 检测记录 -->
<template>
  <div class="outContainer">
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-input
        class="each"
        placeholder="柜机编号"
        v-model="searchInfo.param.id"
      ></el-input>
      <el-input
        class="each"
        placeholder="设备地址"
        v-model="searchInfo.param.address"
      ></el-input>
      <el-select
        class="each"
        placeholder="柜机类型"
        v-model="searchInfo.param.type"
        clearable
      >
        <el-option
          v-for="item in cabinetTypeOptions"
          :key="item.code"
          :label="item.typeName"
          :value="item.code"
        >
        </el-option>
      </el-select>
      <el-cascader
        class="each"
        placeholder="省 / 市 / 区"
        clearable
        v-model="regionId"
        :options="cityOptions"
        :props="{
          expandTrigger: 'click',
          value: 'id',
          label: 'name',
          children: 'childrenList',
          checkStrictly: true,
        }"
        @change="chooseRegion"
      ></el-cascader>
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-search"
        @click="hanldleSearch('search')"
        >搜索</el-button
      >
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-close"
        @click="hanldleSearch('clear')"
        >清空搜索条件</el-button
      >
      <el-button size="mini" @click="openDrawer(null)">新增</el-button>
    </div>
    <!-- 表格 -->
    <div class="tableContainer">
      <el-table :data="tableData" height="100%" style="width: 100%">
        <el-table-column align="center" label="柜机编号" prop="id">
        </el-table-column>
        <el-table-column align="center" label="柜机类型" prop="typeName">
        </el-table-column>
        <el-table-column align="center" label="柜机状态" prop="status">
          <template slot-scope="scope">
            <p>
              {{
                scope.row.status == 1
                  ? '正常'
                  : scope.row.status == 2
                  ? '维护中'
                  : scope.row.status == 3
                  ? '进入维护中'
                  : '--'
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="安装地址" prop="address">
        </el-table-column>
        <el-table-column align="center" label="GPS" prop="GPS">
          <template slot-scope="scope">
            <p v-if="scope.row.longitude">
              {{ scope.row.longitude }},{{ scope.row.latitude }}
            </p>
            <p v-else>--</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="维护状态" prop="address">
          <template v-slot="{ row }">
            {{
              row.status === 1
                ? '正常'
                : row.status === 2
                ? '维护'
                : '进入维护中'
            }}
            <el-button
              size="mini"
              @click="maintenance(row)"
              v-if="row.status == 1"
              >进入维护</el-button
            >
            <el-button size="mini" @click="maintenance(row)" v-else
              >退出维护</el-button
            >
          </template>
        </el-table-column>
        <el-table-column align="center" label="通断电状态" prop="address">
          <template slot-scope="scope">
            {{ scope.row.isPowerOutage === 0 ? '通电' : '断电' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="200">
          <template slot-scope="scope">
            <p>
              <el-button size="mini" @click="detail(scope.row)"
                >系统详情</el-button
              >
            </p>
            <p style="margin-top: 5px">
              <el-button size="mini" @click="openDrawer(scope.row, 'edit')"
                >编辑</el-button
              >
              <el-button size="mini" @click="handleDel(scope.row)"
                >删除</el-button
              >
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <pagination
      v-bind:child-msg="searchInfo"
      @callFather="callFather"
    ></pagination>
    <!-- 新增/编辑 -->
    <el-drawer
      :title="row ? '编辑' : '新增'"
      :visible.sync="addDrawer"
      :before-close="handleClose"
      size="50%"
    >
      <add v-if="addDrawer" @close="handleClose" ref="newForm" :row="row"></add>
    </el-drawer>
  </div>
</template>

<script>
import pagination from '@/components/Pagination.vue'
import {
  getRegionTree,
  getCabinetList,
  delCabinet,
  maintainSystem,
  getCabinetType,
} from '@/api/public.js'
import { removeChildren } from '@/api/publicFun.js'
import add from './add.vue'
export default {
  data() {
    return {
      searchInfo: {
        // 筛选条件
        pageNo: 1,
        pageSize: 10,
        total: 1,
        param: {
          id: null, // 柜机编号
          regionId: null, // 所属行政区id
          type: null, // 柜机类型
          address: null,
        },
      },
      cityOptions: [], // 行政区联级选项
      regionId: [], // 行政区联级选择数据
      tableData: [], // 表格数据
      addDrawer: false, // 新增弹框是否显示
      row: null, // 当前操作数据
      cabinetTypeOptions: [], // 柜机类型选项
    }
  },

  components: {
    pagination,
    add,
  },

  computed: {},

  mounted() {
    this.getPCA()
    this.init()
  },

  methods: {
    // 初始化
    async init() {
      // 获取柜机类型选项
      await getCabinetType({}).then(res => {
        if (res.success) {
          this.cabinetTypeOptions = res.data
        } else {
          this.cabinetTypeOptions = []
        }
      })
      // 获取表格数据
      await getCabinetList(this.searchInfo).then(res => {
        if (res.success && res.data) {
          this.tableData = res.data.list
          this.searchInfo.total = Number(res.data.total)
        } else {
          this.tableData = []
          this.searchInfo.total = 0
        }
      })
    },
    // 获取省市区联动数据
    async getPCA() {
      await getRegionTree().then(res => {
        if (res.success) {
          this.cityOptions = res.data
          this.cityOptions.forEach(element => {
            element = removeChildren(element)
          })
        } else {
          this.cityOptions = []
        }
      })
    },
    // 联级选择行政区事件
    chooseRegion(e) {},
    // 筛选事件
    hanldleSearch(type) {
      this.searchInfo.pageNo = 1
      if (type == 'clear') {
        this.regionId = []
        this.searchInfo.param = {
          id: null,
          regionId: null,
          status: null,
          address: null,
          type: null,
        }
      } else {
        this.searchInfo.param.id =
          this.searchInfo.param.id == '' ? null : this.searchInfo.param.id
        this.searchInfo.param.status =
          this.searchInfo.param.status == ''
            ? null
            : this.searchInfo.param.status
        this.searchInfo.param.address =
          this.searchInfo.param.address == ''
            ? null
            : this.searchInfo.param.address
        let len = this.regionId.length - 1
        if (len < 1) {
          this.searchInfo.param.regionId = null
        } else {
          this.searchInfo.param.regionId = this.regionId[len]
        }
      }
      this.init()
    },
    // 查看系统详情
    detail(row) {
      this.$router.push({
        path: '/cabinetList/systemDetail',
        query: { cabinetId: row.id, type: row.type },
      })
    },
    // 柜机维护
    maintenance(row) {
      this.$confirm('确认进行该操作吗？').then(async () => {
        let data = {
          param: {
            status: row.status == 1 ? 3 : 1,
            cabinetMachineId: row.id,
          },
        }
        const loading = this.$loading({
          lock: true,
          text: '指令下发中',
          spinner: 'el-icon-loading',
          background: 'rgba(0,0,0,0.5)',
        })
        await maintainSystem(data).then(res => {
          loading.close()
          if (res.success) {
            this.$message({
              message: '操作成功',
              type: 'success',
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'fail',
            })
          }
          this.init()
        })
      })
    },
    // 删除柜机
    handleDel(row) {
      this.$confirm('删除后不可恢复，确认进行该操作吗？').then(async () => {
        await delCabinet({ param: row.id }).then(res => {
          this.$message({
            message: res.msg,
            type: res.success ? 'success' : 'fail',
          })
          this.init()
        })
      })
    },
    // 打开侧边抽屉
    openDrawer(row) {
      this.row = row
      this.addDrawer = true
    },
    // 关闭侧边抽屉
    handleClose() {
      this.addDrawer = false
      this.row = null
      this.init()
    },
    // 前往查看设备详情
    equipDetail(row) {
      this.$router.push({
        path: '/cabinetList/equipDetail',
        query: { id: row.id },
      })
    },
    // 分页插件事件
    callFather(parm) {
      this.searchInfo.pageNo = parm.currentPage
      this.init()
    },
  },
}
</script>
<style scoped lang="scss"></style>
